import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { ChevronDown, ChevronUp, Plus } from 'lucide-react';
import './GraduateRankings.css';

const GraduateRankings = () => {
  const [firms, setFirms] = useState([]);
  const [selectedFirm, setSelectedFirm] = useState('');
  const [rankings, setRankings] = useState([]);
  const [expandedUser, setExpandedUser] = useState(null);
  const [notification, setNotification] = useState(null);
  const [flashingUser, setFlashingUser] = useState(null);

  useEffect(() => {
    fetchFirms();
  }, []);

  const fetchFirms = async () => {
    try {
      const { data, error } = await supabase
        .from('firms')
        .select('id, name')
        .order('name');

      if (error) throw error;
      setFirms(data);

      // Find Goodwin's ID and automatically select it
      const goodwinFirm = data.find(firm => firm.name.toLowerCase() === 'goodwin');
      if (goodwinFirm) {
        setSelectedFirm(goodwinFirm.id);
        fetchRankings(goodwinFirm.id);
      }
    } catch (error) {
      console.error('Error fetching firms:', error.message);
    }
  };

  const fetchRankings = async (firmId) => {
    if (firmId) {
      try {
        const { data, error } = await supabase
          .from('firm_user_table')
          .select('user_id, weighted_score')
          .eq('firm_id', firmId)
          .order('weighted_score', { ascending: false });

        if (error) throw error;

        const enhancedData = await Promise.all(data.map(async (ranking) => {
          const [profileData, workExperienceData, applicationData] = await Promise.all([
            fetchUserProfile(ranking.user_id),
            fetchWorkExperience(firmId, ranking.user_id),
            fetchApplicationAnswers(firmId, ranking.user_id)
          ]);

          return {
            ...ranking,
            ...profileData,
            workExperience: workExperienceData,
            applicationAnswers: applicationData,
            weighted_score: parseFloat(ranking.weighted_score) || 0
          };
        }));

        setRankings(enhancedData);
      } catch (error) {
        console.error('Error fetching rankings:', error.message);
      }
    } else {
      setRankings([]);
    }
  };

  const fetchUserProfile = async (userId) => {
    const { data, error } = await supabase
      .from('profiles')
      .select('name, email, education, sub_categories, undergraduate_grades, custom_list')
      .eq('id', userId)
      .single();

    if (error) throw error;
    return data;
  };

  const fetchWorkExperience = async (firmId, userId) => {
    const { data, error } = await supabase
      .from('firm_user_table')
      .select('work_experience')
      .eq('firm_id', firmId)
      .eq('user_id', userId)
      .single();

    if (error) throw error;
    return JSON.parse(data.work_experience || '[]');
  };

  const fetchApplicationAnswers = async (firmId, userId) => {
    const { data, error } = await supabase
      .from('applications_vector')
      .select('question, application_text')
      .eq('firm_id', firmId)
      .eq('user_id', userId);

    if (error) throw error;
    return data;
  };

  const toggleExpand = (userId) => {
    setExpandedUser(expandedUser === userId ? null : userId);
  };

  const formatUndergraduateGrades = (grades) => {
    if (!grades) return [];
    const years = grades.split(', Year ');
    return years.map(year => {
      const [yearName, gradesString] = year.split(' - ');
      const subjects = gradesString.match(/\[([^\]]+)\]/g) || [];
      return {
        year: yearName.replace('Year ', ''),
        subjects: subjects.map(subject => {
          const [name, grade] = subject.slice(1, -1).split(', ');
          return { subject: name, grade };
        })
      };
    });
  };

  const addToCustomList = async (userId) => {
    try {
      const { data, error } = await supabase
        .from('profiles')
        .select('custom_list')
        .eq('id', userId)
        .single();

      if (error) throw error;

      let customList = data.custom_list || '';
      if (!customList.includes('goodwin')) {
        customList += (customList ? ',' : '') + 'goodwin';
      }

      const { error: updateError } = await supabase
        .from('profiles')
        .update({ custom_list: customList })
        .eq('id', userId);

      if (updateError) throw updateError;

      // Update the local state
      setRankings(rankings.map(ranking => 
        ranking.user_id === userId 
          ? { ...ranking, custom_list: customList } 
          : ranking
      ));

      // Show notification
      setNotification('New Candidate Added to Custom List!');
      setTimeout(() => setNotification(null), 3000);

      // Flash the user rectangle
      setFlashingUser(userId);
      setTimeout(() => setFlashingUser(null), 500);

    } catch (error) {
      console.error('Error updating custom list:', error.message);
      setNotification('Failed to add user to custom list. Please try again.');
      setTimeout(() => setNotification(null), 3000);
    }
  };

  return (
    <div className="graduate-rankings">
      <h1>Graduate Rankings</h1>
      {notification && (
        <div className="notification">
          {notification}
        </div>
      )}

      {rankings.length > 0 && (
        <div className="rankings-list">
          <h2>Rankings for Goodwin</h2>
          {rankings.map((ranking, index) => (
            <div key={ranking.user_id} className={`ranking-item ${flashingUser === ranking.user_id ? 'flashing' : ''}`}>
              <div className="ranking-header">
                <div className="ranking-info" onClick={() => toggleExpand(ranking.user_id)}>
                  <h3>{ranking.name} | {ranking.education}, {ranking.sub_categories}</h3>
                  <p className="subheading">Score: {ranking.weighted_score.toFixed(2)}</p>
                  <p className="subheading">{ranking.email}</p>
                </div>
                <div className="ranking-actions">
                  <button 
                    className="toggle-expand"
                    onClick={() => toggleExpand(ranking.user_id)}
                  >
                    {expandedUser === ranking.user_id ? <ChevronUp /> : <ChevronDown />}
                  </button>
                  <button 
                    className="add-to-custom-list"
                    onClick={(e) => {
                      e.stopPropagation();
                      addToCustomList(ranking.user_id);
                    }}
                  >
                    <Plus size={24} />
                  </button>
                </div>
              </div>
              {expandedUser === ranking.user_id && (
                <div className="ranking-details">
                  <h4>Undergraduate Grades</h4>
                  {formatUndergraduateGrades(ranking.undergraduate_grades).map((yearData, yearIndex) => (
                    <div key={yearIndex} className="year-grades">
                      <h5>Year {yearData.year}</h5>
                      <ul>
                        {yearData.subjects.map((subject, subjectIndex) => (
                          <li key={subjectIndex}>{subject.subject}: {subject.grade}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                  <h4>Work Experience</h4>
                  <ul>
                    {ranking.workExperience.map((exp, i) => (
                      <li key={i}>{exp.title} - {exp.time}</li>
                    ))}
                  </ul>
                  <h4>Application Answers</h4>
                  {ranking.applicationAnswers.map((answer, i) => (
                    <div key={i} className="application-answer">
                      <h5>{answer.question}</h5>
                      <p>{answer.application_text}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default GraduateRankings;