import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { ChevronDown, ChevronUp } from 'lucide-react';
import './AutoGen.css';

const AutoGen = () => {
  const [rankings, setRankings] = useState([]);
  const [expandedUser, setExpandedUser] = useState(null);
  const [weights, setWeights] = useState({
    workexp: 0.4,
    education: 0.3,
    opentext: 0.3
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchGoodwinRankings();
  }, []);

  const fetchGoodwinRankings = async () => {
    try {
      const { data: firmData, error: firmError } = await supabase
        .from('firms')
        .select('id')
        .eq('name', 'Goodwin')
        .single();

      if (firmError) throw firmError;

      const goodwinId = firmData.id;

      const { data, error } = await supabase
        .from('firm_user_table')
        .select('user_id, workexp_score, education_score, opentext_score')
        .eq('firm_id', goodwinId);

      if (error) throw error;

      const enhancedData = await Promise.all(data.map(async (ranking) => {
        const profileData = await fetchUserProfile(ranking.user_id);
        return {
          ...ranking,
          ...profileData,
          weighted_score: calculateWeightedScore(ranking)
        };
      }));

      setRankings(enhancedData.sort((a, b) => b.weighted_score - a.weighted_score).slice(0, 10));
    } catch (error) {
      console.error('Error fetching Goodwin rankings:', error.message);
    }
  };

  const fetchUserProfile = async (userId) => {
    const { data, error } = await supabase
      .from('profiles')
      .select('name, email, education')
      .eq('id', userId)
      .single();

    if (error) throw error;
    return data;
  };

  const calculateWeightedScore = (ranking) => {
    return (
      (ranking.workexp_score * weights.workexp) +
      (ranking.education_score * weights.education) +
      (ranking.opentext_score * weights.opentext)
    );
  };

  const handleWeightChange = (category, value) => {
    const newWeights = { ...weights, [category]: parseFloat(value) };
    setWeights(newWeights);
    setRankings(rankings.map(ranking => ({
      ...ranking,
      weighted_score: calculateWeightedScore({ ...ranking, ...newWeights })
    })).sort((a, b) => b.weighted_score - a.weighted_score).slice(0, 10));
  };

  const toggleExpand = (userId) => {
    setExpandedUser(expandedUser === userId ? null : userId);
  };

  const handleSendInvite = async () => {
    setIsLoading(true);
    // Simulating an API call
    await new Promise(resolve => setTimeout(resolve, 1000));
    setIsLoading(false);
    alert('Meeting invites sent successfully!');
  };

  return (
    <div className="auto-gen">
      <h1>Auto Generate Top 10</h1>
      <div className="controls">
        <div className="weight-sliders">
          <div className="weight-slider">
            <label>Work Experience:</label>
            <input
              type="range"
              min="0"
              max="1"
              step="0.1"
              value={weights.workexp}
              onChange={(e) => handleWeightChange('workexp', e.target.value)}
            />
            <span>{weights.workexp.toFixed(1)}</span>
          </div>
          <div className="weight-slider">
            <label>Education:</label>
            <input
              type="range"
              min="0"
              max="1"
              step="0.1"
              value={weights.education}
              onChange={(e) => handleWeightChange('education', e.target.value)}
            />
            <span>{weights.education.toFixed(1)}</span>
          </div>
          <div className="weight-slider">
            <label>Open Text:</label>
            <input
              type="range"
              min="0"
              max="1"
              step="0.1"
              value={weights.opentext}
              onChange={(e) => handleWeightChange('opentext', e.target.value)}
            />
            <span>{weights.opentext.toFixed(1)}</span>
          </div>
        </div>
        <button
          className="send-invite-btn"
          onClick={handleSendInvite}
          disabled={isLoading || rankings.length === 0}
        >
          {isLoading ? 'Sending...' : 'Send Meeting Invite'}
        </button>
      </div>

      {rankings.length > 0 && (
        <div className="rankings-list">
          <h2>Top 10 for Goodwin</h2>
          {rankings.map((ranking, index) => (
            <div key={ranking.user_id} className="ranking-item">
              <div className="ranking-header" onClick={() => toggleExpand(ranking.user_id)}>
                <div className="ranking-info">
                  <h3>{ranking.name} | {ranking.education}</h3>
                  <p className="subheading">Score: {ranking.weighted_score.toFixed(2)}</p>
                  <p className="subheading">{ranking.email}</p>
                </div>
                {expandedUser === ranking.user_id ? <ChevronUp /> : <ChevronDown />}
              </div>
              {expandedUser === ranking.user_id && (
                <div className="ranking-details">
                  <p>Work Experience Score: {ranking.workexp_score.toFixed(2)}</p>
                  <p>Education Score: {ranking.education_score.toFixed(2)}</p>
                  <p>Open Text Score: {ranking.opentext_score.toFixed(2)}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AutoGen;