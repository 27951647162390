import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { ChevronDown, ChevronUp } from 'lucide-react';
import './Communications.css';

const Communications = () => {
  const [users, setUsers] = useState([]);
  const [expandedUser, setExpandedUser] = useState(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .in('status', ['Pending', 'Accepted'])
        .order('status', { ascending: false });

      if (error) throw error;

      const enhancedData = await Promise.all(data.map(async (user) => {
        const workExperienceData = await fetchWorkExperience(user.id);
        return {
          ...user,
          workExperience: workExperienceData,
        };
      }));

      setUsers(enhancedData);
    } catch (error) {
      console.error('Error fetching users:', error.message);
    }
  };

  const fetchWorkExperience = async (userId) => {
    try {
      const { data, error } = await supabase
        .from('firm_user_table')
        .select('work_experience')
        .eq('user_id', userId);

      if (error) throw error;

      // Combine all work experiences into a single array
      const allWorkExperiences = data.reduce((acc, item) => {
        const experiences = JSON.parse(item.work_experience || '[]');
        return [...acc, ...experiences];
      }, []);

      return allWorkExperiences;
    } catch (error) {
      console.error('Error fetching work experience:', error.message);
      return [];
    }
  };

  const toggleExpand = (userId) => {
    setExpandedUser(expandedUser === userId ? null : userId);
  };

  const formatUndergraduateGrades = (grades) => {
    if (!grades) return [];
    const years = grades.split(', Year ');
    return years.map(year => {
      const [yearName, gradesString] = year.split(' - ');
      const subjects = gradesString.match(/\[([^\]]+)\]/g) || [];
      return {
        year: yearName.replace('Year ', ''),
        subjects: subjects.map(subject => {
          const [name, grade] = subject.slice(1, -1).split(', ');
          return { subject: name, grade };
        })
      };
    });
  };

  return (
    <div className="communications">
      <h1>Communications</h1>
      {users.length > 0 ? (
        <div className="users-list">
          {users.map((user) => (
            <div key={user.id} className="user-item">
              <div className="user-header">
                <div className="user-info" onClick={() => toggleExpand(user.id)}>
                  <h3>{user.name} | {user.education}</h3>
                  <p className="subheading">{user.email}</p>
                </div>
                <div className="user-actions">
                  <span className={`status-badge ${user.status.toLowerCase()}`}>
                    {user.status}
                  </span>
                  <button 
                    className="toggle-expand"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleExpand(user.id);
                    }}
                  >
                    {expandedUser === user.id ? <ChevronUp /> : <ChevronDown />}
                  </button>
                </div>
              </div>
              {expandedUser === user.id && (
                <div className="user-details">
                  <h4>Undergraduate Grades</h4>
                  {formatUndergraduateGrades(user.undergraduate_grades).map((yearData, yearIndex) => (
                    <div key={yearIndex} className="year-grades">
                      <h5>Year {yearData.year}</h5>
                      <ul>
                        {yearData.subjects.map((subject, subjectIndex) => (
                          <li key={subjectIndex}>{subject.subject}: {subject.grade}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                  <h4>Work Experience</h4>
                  <ul>
                    {user.workExperience.map((exp, i) => (
                      <li key={i}>{exp.title} - {exp.time}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <p>No users found with Pending or Accepted status.</p>
      )}
    </div>
  );
};

export default Communications;