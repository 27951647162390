import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { ChevronDown, ChevronUp, Minus } from 'lucide-react';
import './CustomList.css';

const CustomList = () => {
  const [users, setUsers] = useState([]);
  const [expandedUser, setExpandedUser] = useState(null);
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .like('custom_list', '%goodwin%');

      if (error) throw error;

      const enhancedData = await Promise.all(data.map(async (user) => {
        const workExperienceData = await fetchWorkExperience(user.id);
        return {
          ...user,
          workExperience: workExperienceData,
        };
      }));

      setUsers(enhancedData);
    } catch (error) {
      console.error('Error fetching users:', error.message);
    }
  };

  const fetchWorkExperience = async (userId) => {
    try {
      const { data, error } = await supabase
        .from('firm_user_table')
        .select('work_experience')
        .eq('user_id', userId);

      if (error) throw error;

      // Combine all work experiences into a single array
      const allWorkExperiences = data.reduce((acc, item) => {
        const experiences = JSON.parse(item.work_experience || '[]');
        return [...acc, ...experiences];
      }, []);

      return allWorkExperiences;
    } catch (error) {
      console.error('Error fetching work experience:', error.message);
      return [];
    }
  };

  const toggleExpand = (userId) => {
    setExpandedUser(expandedUser === userId ? null : userId);
  };

  const formatUndergraduateGrades = (grades) => {
    if (!grades) return [];
    const years = grades.split(', Year ');
    return years.map(year => {
      const [yearName, gradesString] = year.split(' - ');
      const subjects = gradesString.match(/\[([^\]]+)\]/g) || [];
      return {
        year: yearName.replace('Year ', ''),
        subjects: subjects.map(subject => {
          const [name, grade] = subject.slice(1, -1).split(', ');
          return { subject: name, grade };
        })
      };
    });
  };

  const removeFromCustomList = async (userId) => {
    try {
      const { error } = await supabase
        .from('profiles')
        .update({ custom_list: '' })
        .eq('id', userId);

      if (error) throw error;

      setUsers(users.filter(user => user.id !== userId));
      setNotification('Candidate removed from list');
      setTimeout(() => setNotification(null), 3000);
    } catch (error) {
      console.error('Error removing user from custom list:', error.message);
      setNotification('Failed to remove user from custom list. Please try again.');
      setTimeout(() => setNotification(null), 3000);
    }
  };

  return (
    <div className="custom-list">
      <h1>Custom List</h1>
      {notification && (
        <div className="notification">
          {notification}
        </div>
      )}
      <p className="user-counter">Total Candidates: {users.length}</p>
      {users.length > 0 ? (
        <div className="users-list">
          {users.map((user) => (
            <div key={user.id} className="user-item">
              <div className="user-header">
                <div className="user-info" onClick={() => toggleExpand(user.id)}>
                  <h3>{user.name} | {user.education}, {user.sub_categories}</h3>
                  <p className="subheading">{user.email}</p>
                </div>
                <div className="user-actions">
                  <button 
                    className="remove-from-list"
                    onClick={(e) => {
                      e.stopPropagation();
                      removeFromCustomList(user.id);
                    }}
                  >
                    <Minus size={24} />
                  </button>
                  <button 
                    className="toggle-expand"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleExpand(user.id);
                    }}
                  >
                    {expandedUser === user.id ? <ChevronUp /> : <ChevronDown />}
                  </button>
                </div>
              </div>
              {expandedUser === user.id && (
                <div className="user-details">
                  <h4>Undergraduate Grades</h4>
                  {formatUndergraduateGrades(user.undergraduate_grades).map((yearData, yearIndex) => (
                    <div key={yearIndex} className="year-grades">
                      <h5>Year {yearData.year}</h5>
                      <ul>
                        {yearData.subjects.map((subject, subjectIndex) => (
                          <li key={subjectIndex}>{subject.subject}: {subject.grade}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                  <h4>Work Experience</h4>
                  <ul>
                    {user.workExperience.map((exp, i) => (
                      <li key={i}>{exp.title} - {exp.time}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <p>No users found in the custom list.</p>
      )}
    </div>
  );
};

export default CustomList;