import React, { useState, useEffect } from 'react';
import { Search, ChevronDown, ChevronUp } from 'lucide-react';
import { supabase } from '../supabaseClient';
import './StudentSearch.css';

const StudentSearch = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [expandedUser, setExpandedUser] = useState(null);

  const handleSearch = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch('/api/embed_row', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ search_query: searchQuery }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setSearchResults(data);
    } catch (e) {
      console.error('Search error:', e);
      setError('An error occurred while searching. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchProfiles = async () => {
      if (searchResults) {
        const updatedResults = await Promise.all(
          searchResults.map(async (result) => {
            const { data, error } = await supabase
              .from('profiles')
              .select('name, email, education')
              .eq('id', result.user_id)
              .single();

            if (error) {
              console.error('Error fetching profile:', error);
              return result;
            }

            return { ...result, profile: data };
          })
        );

        setSearchResults(updatedResults);
      }
    };

    fetchProfiles();
  }, [searchResults]);

  const toggleExpand = (userId) => {
    setExpandedUser(expandedUser === userId ? null : userId);
  };

  return (
    <div className="student-search">
      <h2>Student Search</h2>
      <form onSubmit={handleSearch} className="search-form">
        <input
          type="text"
          placeholder="Search for students..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-input"
        />
        <button type="submit" className="search-button" disabled={isLoading}>
          <Search size={20} />
        </button>
      </form>

      {isLoading && <p>Loading...</p>}
      {error && <p className="error">{error}</p>}
      
      {searchResults && (
        <div className="search-results">
          <h3>Search Results:</h3>
          {searchResults.map((result) => (
            <div key={result.user_id} className="result-item">
              <div className="result-header" onClick={() => toggleExpand(result.user_id)}>
                <div className="result-info">
                  <h4>{result.profile?.name || 'Name not found'}</h4>
                  <p>{result.profile?.email || 'Email not found'}</p>
                  <p>{result.profile?.education || 'Education not found'}</p>
                </div>
                <div className="result-score">
                  <p>Similarity: {result.similarity.toFixed(4)}</p>
                </div>
                {expandedUser === result.user_id ? <ChevronUp /> : <ChevronDown />}
              </div>
              {expandedUser === result.user_id && (
                <div className="result-details">
                  <h5>Bulk Content:</h5>
                  <p>{result.bulk_content}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default StudentSearch;