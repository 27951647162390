import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LayoutRecruit from './pages/LayoutRecruit';
import StudentSearch from './pages/StudentSearch';
import GraduateRankings from './pages/GraduateRankings';
import AutoGen from './pages/AutoGen';
import CustomList from './pages/CustomList';
import Communications from './pages/Communications';
import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LayoutRecruit />}>
          <Route path="student-search" element={<StudentSearch />} />
          <Route path="graduate-rankings" element={<GraduateRankings />} />
          <Route path="auto-gen" element={<AutoGen />} />
          <Route path="custom-list" element={<CustomList />} />
          <Route path="communications" element={<Communications />} />
          <Route index element={<StudentSearch />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;