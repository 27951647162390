import React, { useState } from 'react';
import { Menu } from 'lucide-react';
import { Link, useLocation, useNavigate, Outlet } from 'react-router-dom';
import './LayoutRecruit.css';

const Layout = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // Example recruiter name
  const recruiterName = "HR Agent";

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setShowOverlay(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
    setShowOverlay(false);
  };

  const handleLogout = () => {
    // Example logout functionality
    console.log("Logout clicked");
    navigate('/login');
  };

  return (
    <div className="layout">
      <div className={`sidebar ${menuOpen ? 'open' : ''}`}>
        <div className="sidebar-content">
          <div className="logo">TalentLex Recruit</div>
          <nav>
            <ul>
              <li className="section-title">Recruitment Tools</li>
              <div className='separator'></div>
              <li className={location.pathname === "/student-search" ? "active" : ""}>
                <Link to="/student-search">Student Search</Link>
              </li>
              <li className={location.pathname === "/graduate-rankings" ? "active" : ""}>
                <Link to="/graduate-rankings">Graduate Rankings</Link>
              </li>
              
              <li className="section-title">Shortlist</li>
              <div className='separator'></div>
              <li className={location.pathname === "/auto-gen" ? "active" : ""}>
                <Link to="/auto-gen">Auto Gen</Link>
              </li>
              <li className={location.pathname === "/custom-list" ? "active" : ""}>
                <Link to="/custom-list">Custom List</Link>
              </li>

              <li className="section-title">Recruited Talent</li>
              <div className='separator'></div>
              <li className={location.pathname === "/communications" ? "active" : ""}>
                <Link to="/communications">Communications</Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="user-info">
          <div className='separator'></div>
          <div className='user-name'>{recruiterName}</div>
          <div className='user-role'>Goodwin Recruiter</div>
          <button onClick={handleLogout} className="logout-btn">Log Out</button>
        </div>
      </div>
      <div className="main-content">
        <button className="menu-toggle" onClick={toggleMenu}>
          <Menu size={24} />
        </button>
        <div className="content-area">
          <Outlet />
        </div>
      </div>
      {showOverlay && (
        <div className="menu-overlay" onClick={closeMenu}></div>
      )}
    </div>
  );
};

export default Layout;